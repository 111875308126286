<template>
  <div class="onboarding-layout h-100">
    <v-app-bar app fixed :height="56">
      <v-spacer />

      <header-logo height="24" :light="false" />

      <v-spacer />
    </v-app-bar>
    <v-main class="h-100">
      <slot />
    </v-main>
  </div>
</template>

<script>
import HeaderLogo from "@/components/Layout/Header/HeaderLogo";
export default {
  name: "OnboardingLayout",
  components: { HeaderLogo },
};
</script>
